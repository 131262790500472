export default {
  blogerName: 'RYAN SLOTS',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@Ryanzera777',
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://monrocasino.life/c6c5ade3b',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/c7a2b069d',
      gameTitle: 'Jet Air',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/ca65a3cf7',
      gameTitle: 'Doors of Sol',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c355c30f0',
      gameTitle: 'Doors Of Fresh',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c948e8dba',
      gameTitle: 'IZZI ART',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c065a6494',
      gameTitle: 'LEGZO PUNK',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/cbdebd228',
      gameTitle: 'STARDA QUEEN',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/c86269f55',
      gameTitle: 'Alien Fruits ',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>RYAN7</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'RYAN7',
  casinoBonus1: '<strong>100% + 500FS</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>para registro con código promocional</div>',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
  attentionText: ''
}
